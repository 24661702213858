<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-flex xs2>
            <v-menu
              ref="menu1"
              v-model="beginDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedMonth"
                  :label="$t('global.month')"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" type="month" color="primary" @input="beginDateMenu = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-spacer />
          <!-- <id-button @click="onCreate" class="mr-2">
            <template #icon>
              <v-icon left>mdi-plus</v-icon>
            </template>
            {{ $t("buttons.add", []) }}
          </id-button> -->
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td>
                <employee-profile :employee="row.item.sender" />
              </td>
              <td>
                {{ row.item.name }}
              </td>
              <td>{{ row.item.type.name }}</td>

              <td>{{ row.item.createdDateTime | formattedDate }}</td>
              <td>
                <file-download-button :url="row.item.url" />
              </td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description Performance Component Logic
   * @date 28.07.2020
   */

  import { DOCUMENTS } from "../query";

  export default {
    name: "Documents",
    data: vm => ({
      beginDateMenu: false,
      date: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM"),
      table: {
        loading: false,
        data: [],
        criteria: {
          sender: vm.$store.state.auth.user.id,
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {},
        pagination: {
          page: 1,
          rowsPerPage: 10,
          sortBy: "name",
          descending: false
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.document.sender"),
            value: "sender.firstName",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.document.name"),
            value: "name",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.document.type"),
            value: "type.name",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },

          {
            text: vm.$t("hr.document.date"),
            value: "createdDateTime",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "url",
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      },
      date: {
        handler(v) {
          this.table.criteria.beginDate = this.$moment(v)
            .startOf("month")
            .format("YYYY-MM-DD");
          this.table.criteria.endDate = this.$moment(v)
            .endOf("month")
            .format("YYYY-MM-DD");
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    computed: {
      formattedMonth() {
        return this.$moment(this.date).format("MMMM");
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: DOCUMENTS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateDocuments.content || [];
              this.table.totalItems = data.filterWithPaginateDocuments.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      onCreate() {
        this.$router.push({ name: "document_create" });
      }
    }
  };
</script>

<style scoped></style>
